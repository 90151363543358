<template>
    <!-- 占位符 -->
  <router-view></router-view>
</template>

<script>
export default {
  name: 'MyApp'
}
</script>

<style lang="less" scoped></style>
